import clamp from 'lodash/clamp';
import { Replayer as RrwebReplayer } from 'rrweb';
import { playerConfig } from 'rrweb/typings/types';

import { ReplayUrlInfo } from '@sprigShared/replays';

import { isDimensions } from 'utils';

import { fetchReplay } from './fetch';
import { ReplayData } from './types';

enum FileLoadState {
  Unloaded = 'unloaded',
  Loaded = 'loaded',
  Loading = 'loading',
}

interface ReplayFileLoadState {
  [url: string]: FileLoadState;
}

interface IntendedPlayerState {
  playing: boolean;
  timestamp: number;
  awaitingSeek: boolean;
}

interface ResizeEvent {
  type: EventType.Resize;
  width: number;
  height: number;
}

interface StartPlayEvent {
  type: EventType.Start;
  timestamp: number;
}

interface UpdateEvent {
  type: EventType.Update;
}

enum EventType {
  Update = 'update',
  Start = 'start',
  Resize = 'resize',
}

type ReplayEvent = ResizeEvent | StartPlayEvent | UpdateEvent;

export class MultiFileReplayer {
  private readonly _intendedPlayerState: IntendedPlayerState = {
    playing: true,
    timestamp: 0,
    awaitingSeek: false,
  };
  private _firstEventTimestamp: number | null = null;
  private _replayer: RrwebReplayer | null = null;
  private _files: ReplayUrlInfo[] = [];
  private _loadState: ReplayFileLoadState = {};
  private readonly _subscriptions: ((event: ReplayEvent) => void)[] = [];
  private _playerConfig: Partial<playerConfig> = {};
  private _interval: number | null = null;

  public subscribe(callback: (event: ReplayEvent) => void) {
    this._subscriptions.push(callback);
    return {
      unsubscribe: () => {
        const index = this._subscriptions.indexOf(callback);
        if (index >= 0) {
          this._subscriptions.splice(index, 1);
        } else {
          throw new Error(`Multifile Replayer: Tried to unsubscribe handler that wasn't subscribed`);
        }
      },
    };
  }

  private fireSubscriptions(event: ReplayEvent = { type: EventType.Update }) {
    this._subscriptions.forEach((s) => s(event));
  }

  public init(playerConfig: Partial<playerConfig>, replayUrls: ReplayUrlInfo[], startTime?: number) {
    if (!replayUrls.length) return;
    this._playerConfig = playerConfig;
    if (startTime) {
      this._intendedPlayerState.timestamp = startTime;
    }
    const sortedFiles = replayUrls
      .map((f) => ({
        url: f.url,
        startTimestamp: f.startTimestamp ?? 0,
        endTimestamp: f.endTimestamp ?? Number.MAX_SAFE_INTEGER,
      }))
      .sort((a, b) => {
        const aStart = a.startTimestamp ?? 0;
        const bStart = b.startTimestamp ?? 0;
        return aStart - bStart;
      });
    const startTimestamp = replayUrls[0].startTimestamp ?? 0;
    // Convert so all timestamps consider the start of the replay to be zero
    sortedFiles.forEach((f) => {
      f.startTimestamp -= startTimestamp;
      f.endTimestamp -= startTimestamp;
    });
    this._files = sortedFiles;
    this._files.forEach((f) => {
      this._loadState[f.url] = FileLoadState.Unloaded;
    });
    this.loadFilesIfNeeded();
  }

  public destroy() {
    if (this._interval) clearInterval(this._interval);
  }

  private createReplayer(initialEvents: ReplayData) {
    this._replayer = new RrwebReplayer(initialEvents, this._playerConfig);
    this._interval = window.setInterval(() => this.updateFromPlayerState(), 100);
    this._replayer.service.subscribe(() => this.updateFromPlayerState());
    this.fireSubscriptions({
      type: EventType.Start,
      timestamp: initialEvents[0].timestamp + this._intendedPlayerState.timestamp,
    });
    this._firstEventTimestamp = initialEvents[0].timestamp;
    this._replayer.on('resize', (dimensions) => {
      if (isDimensions(dimensions)) {
        this.fireSubscriptions({
          type: EventType.Resize,
          height: dimensions.height,
          width: dimensions.width,
        });
      }
    });
    if (this._intendedPlayerState.timestamp !== 0) {
      this.seek(this._intendedPlayerState.timestamp);
    }
  }

  private updateFromPlayerState() {
    this._intendedPlayerState.timestamp = this.currentTimestamp;
    // If at the end of the video, change intended player state to paused
    if (!this.isReplayerPlaying && this.currentTimestamp >= this.maxReplayTimestamp) {
      this._intendedPlayerState.playing = false;
    }
    this.loadFilesIfNeeded();
    this.fireSubscriptions();
  }

  public get maxReplayTimestamp() {
    if (!this._replayer) return 0;
    if (this._files.length === 1) return this._replayer.getMetaData()?.totalTime ?? 0;
    return this._files[this._files.length - 1].endTimestamp;
  }

  public get iframe() {
    return this._replayer?.iframe;
  }

  public get wrapperElem() {
    return this._replayer?.wrapper;
  }

  public get startTimestamp() {
    return this._firstEventTimestamp;
  }

  public seek(timestamp: number, play: boolean | null = null) {
    const targetTime = this.maxReplayTimestamp ? clamp(timestamp, 0, this.maxReplayTimestamp) : timestamp;
    this._intendedPlayerState.timestamp = targetTime;
    if (play !== null) {
      this._intendedPlayerState.playing = play;
    }
    this._intendedPlayerState.awaitingSeek = true;
    if (this.isReplayerPlaying) {
      this._replayer?.pause();
      this.updateFromPlayerState();
    }
  }

  public pause() {
    this._intendedPlayerState.playing = false;
    this._replayer?.pause();
    this.updateFromPlayerState();
  }

  private async loadFilesIfNeeded() {
    if (!this._files) return;
    const filesToLoad = this.getFileUrlsForTime(this.currentTimestamp);
    await Promise.all(filesToLoad.map((url) => this.loadFile(url)));
    this.startReplayerIfNeeded();
  }

  private startReplayerIfNeeded() {
    const filesForCurrentTimestamp = this.getFileUrlsForTime(this.currentTimestamp);
    const currentFilesLoaded = filesForCurrentTimestamp.every((url) => this._loadState[url] === FileLoadState.Loaded);
    if (
      currentFilesLoaded &&
      ((!this.isReplayerPlaying && this._intendedPlayerState.playing) || this._intendedPlayerState.awaitingSeek)
    ) {
      this._intendedPlayerState.awaitingSeek = false;
      if (this._intendedPlayerState.playing) {
        this._replayer?.play(this._intendedPlayerState.timestamp);
      } else {
        this._replayer?.pause(this._intendedPlayerState.timestamp);
      }
      this.updateFromPlayerState();
    }
  }

  public get isLoading() {
    return (
      ((!this.isReplayerPlaying && this._intendedPlayerState.playing) || this._intendedPlayerState.awaitingSeek) &&
      this._files.some((f) => this._loadState[f.url] === FileLoadState.Loading)
    );
  }

  private async loadFile(url: string) {
    if (this._loadState[url] !== FileLoadState.Unloaded) return;
    this._loadState[url] = FileLoadState.Loading;
    const data = await fetchReplay(url);
    this._loadState[url] = FileLoadState.Loaded;
    if (this._replayer) {
      data.forEach((e) => {
        this._replayer?.addEvent(e);
      });
    } else {
      this.createReplayer(data);
    }
    return data;
  }

  private get isReplayerPlaying() {
    return !!this._replayer?.service?.state?.matches('playing');
  }

  public get currentTimestamp() {
    return this._replayer && this.isReplayerPlaying
      ? this._replayer?.getCurrentTime()
      : this._intendedPlayerState.timestamp;
  }

  public get currentPlayerState() {
    return {
      timestamp: this.currentTimestamp,
      isPlaying: this._intendedPlayerState.playing,
      isLoading: this.isLoading,
      totalTime: this.maxReplayTimestamp,
      speed: this._replayer?.config?.speed ?? 1,
      skipInactive: this._replayer?.config?.skipInactive,
    };
  }

  public setSpeed(speed: number) {
    this._replayer?.setConfig({ speed });
  }

  public setSkipInactive(skipInactive: boolean) {
    this._replayer?.setConfig({ skipInactive });
  }

  /**
   * To ensure a smooth playing experience, we always load the next file as well
   * as the one that contains the current timestamp
   */
  private getFileUrlsForTime(timestamp: number) {
    let lastFile: ReplayUrlInfo | null = null;
    for (const f of this._files) {
      const index = this._files.indexOf(f);
      const nextFile = index + 1 < this._files.length ? this._files[index + 1] : null;
      if (f.startTimestamp <= timestamp && f.endTimestamp >= timestamp) {
        const result = [f.url];
        if (nextFile) {
          result.push(nextFile.url);
        }
        return result;
      }
      if (f.startTimestamp > timestamp) {
        if (lastFile) return [lastFile.url, f.url];
        return [f.url];
      }
      lastFile = f;
    }
    return [];
  }
}
